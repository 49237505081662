


<template>
<div>
<HeaderMain  v-show="!loadingEvent" @messageFromHeader="searchEvents"/>
<div>

</div>
<div>
       <SlideShow/>
       <SearchCourse v-if="!main" :courses="search" />

 <body>

    


    <section  class="clean-block clean-product dark" style="background: rgb(10,2,49);   background: radial-gradient(circle,rgb(10,2,49) 20%, rgb(0,0,0) 100%);">
      
      
      <PreloaderComponent v-show="validateProc()===false"/>

      
      <div  v-show="validateProc()===true" class="container" style="padding-top: 40px; padding-bottom: 40px;">


                <div class="block-content" style="background: transparent; border: 2px solid rgba(204,204,204,0.36); border-radius: 30px; padding: 20px;">
                  <div id="cognito-form"></div>

                  
                  
                    
       
                    
                    <div class="clean-related-items"></div>
                </div>
            </div>
  </section>




 </body>

    <footer v-show="!loadingEvent">
        <FooterMain/>
    </footer>


</div>
</div>

</template>

<script>
// @ is an alias to /src
import { apiService } from "../../common/api.services.js";

import HeaderMain from "@/components/HeaderMain.vue"
import FooterMain from "@/components/FooterMain.vue";
import PreloaderComponent from "@/components/PreloaderComponent.vue"


export default {

  name: "ZocialView",
    mounted() {
      // Carga el script de Cognito Forms de forma dinámica
      const script = document.createElement("script");
      script.src = "https://www.cognitoforms.com/f/seamless.js";
      script.async = true;
  
      // Configura los atributos necesarios
      script.setAttribute("data-key", "5mwehIbeiUCGXSRJH0Anpw");
      script.setAttribute("data-form", "84");
  
      // Inserta el script en el contenedor correspondiente
      document.getElementById("cognito-form").appendChild(script);
  
      // Opcional: Escucha el evento de carga
      script.onload = () => {
        console.log("Cognito Forms script loaded successfully.");
      };
  
      // Maneja errores al cargar el script
      script.onerror = () => {
        console.error("Failed to load the Cognito Forms script.");
      };
    },
   props: {

    slug: {
      required: true
    },
    

    //log25
    
  },

 


  data(){
    return{
      search:[],
      searchField:"",
      main: true,
      err: false,
      requestUserStatus: false,
      requestUserType: false,
      showLoading: false,
      event: {},
      venue: {},
      category: {},
      city: "",
      eventTickets: [],
      updates: [],
      host: null,
      code: null,
      next: null,
      errCode: false,
      promoCode: "",
      saleMode: false,
      order_fee: 0,
      order_total:0,
      products:0,
      step: 0,
      name: "",
      last_name: "",
      phone: "",
      email: "",
      cc: "",
      ticketsTable: [],
      order_subtotal: 0,
      wanted: false,
      saleResult: 0,
      auto_sale: false,
      id: null,
      main_fn:"",
      main_ln:"",
      main_ph:"",
      customer: {},
      map: false,
      selectedSeats: [],
      seatsProducts: [],
      mapValidator: 0,
      mapLenght: 0,
      showCode: false,
      loadingEvent: false,
      event_access: " Comprar Tickets",
      showModal: true,
      pixelId: null,
      scriptLoaded: false,

     

    };
  },
  components: {
    HeaderMain,
    FooterMain,
  
    PreloaderComponent,
  

  },


  created(){
    
   





  },
  methods:{
      getEvent() {
      this.wanted = this.$route.query.wanted



      
      this.loadingEvent=true
      // make a GET Request to the questions list endpoint and populate the questions array


      let endpoint = `/api/events/${this.slug}/`;

      console.log(this.slug)

      if (this.slug=="PSYCHO-LAB-HORROR-NIGNT"  ){
        endpoint = `/api/events/PSYCHO-LAB-HORROR-NIGHT/`;
      }

      


      this.showLoading = true;
      apiService(endpoint).then((data) => {
        this.event = data;

        //funciona solo MULALO 2 DIC 23
        if (this.event.id==90){
          window.location.href = "/event/Tambo/";
        }

        if (this.event.access!=1){
          this.showCode=true

        }
        this.id=data.id
        this.category = data.category
        this.venue = data.venue
        this.city = data.venue.city.name
        this.showLoading = false;
        this.updates = data.updates
        this.event_id = data.id
        
        if(data.meta_pixel!=""){
          this.pixelId=data.meta_pixel
        }

        this.getEventTickets()
        if (data.access==6){
          this.event_access=" Aplicar a Grupo"
        }
        

      });
      

     
    },

    
    loadMetaPixel() {
      if (this.pixelId) {
        console.log("oasi");
        (function(f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function() {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq('init', this.pixelId);
        window.fbq('track', 'PageView');
      }
    },


    startSale(){

      this.saleMode=true
      this.step=1
      this.$refs['anchor1'].scrollIntoView({behavior: "smooth"})


    },
    getEventTickets() {
       
      
      
      this.code = this.$route.query.co
      this.wanted = this.$route.query.wanted



// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
        let endpoint = "/api/event-tickets/?event=" + this.id
        console.log(endpoint)
        if (this.code){
            endpoint = endpoint + "&code=" + this.code 
        }

          if (this.host){
            endpoint = endpoint + "&host=" + this.host 
        }
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);
          this.showLoading = false;
          this.loadingEvent=false



          if(this.$route.query.step==3){
            this.step=3

    
     }

        
        if (this.wanted){
          this.startSale()

        }

        if (this.pixelId!=null){
          this.loadMetaPixel();
        }



        })

       
      
        
    },
   



newEventTicketsSearch() {


// make a GET Request to the questions list endpoint and populate the questions array
     
          //tipo 1 para generico y tipo 2 para mis cursos y usar el mismo componente
      
          let   endpoint = "/api/event-tickets/?event=" + this.id + "&code=" + this.promoCode 
      
        
      if (this.next) {
        endpoint = this.next;
      }
      this.loadingQuestions = true;



      apiService(endpoint)
        .then(data => {
          console.log(endpoint)
          this.eventTickets=this.eventTickets.concat(data);

          if (data.length==0){
            this.errCode=true
          }else{
             this.errCode=false
             this.code=this.promoCode
          }
          this.showLoading = false;
        })
      
        
    },
   
  startUserCreation(products, subtotal, fee, total, map){
    console.log("muestro los productos")
  

    console.log(products)
    this.products=products
    console.log(this.products.length)
    this.order_total=total
    this.order_fee=fee
    this.order_subtotal=subtotal
   

    if (this.event.id==16){ //modificar luego
      this.customer=1 
      this.step=3
    }else{ //caja


      if (map>0){
      for (let index = 0; index < this.products.length; index++) {
        console.log("muestro  el produ")

        console.log(this.products[index])

  
      if (this.products[index].map==true && this.products[index].quantity!=0){

            this.seatsProducts.push(this.products[index])
      

      }

      this.mapLenght=this.seatsProducts.length

      this.map=true
      this.step=5

    }
    this.products = this.products.filter(product => product.map == false);// saco array

    
    }else{
      this.step++

    }

  }



  },


  startUserCreation1(selectedIds){
    console.log("ok ok ok")
    console.log(selectedIds)
    this.selectedSeats= this.selectedSeats.concat(selectedIds)
    if ((this.mapValidator+1)==this.mapLenght){
    this.map=false
    this.step=2

  }
  this.mapValidator++

  
},


  startGuestNames(name, last_name, phone, email, cc){
    this.name=name
    this.last_name=last_name
    this.phone=phone
    this.email=email
    this.cc=cc
    this.step++
  },


    startFinalSale(guests, id){
    
    this.ticketsTable=guests

    this.customer=id
    

    this.step++
  },

  completeSale(number, tck){

      console.log("lleue")

      let  conts = []

        
       
       if (this.pixelId ) {
        
      for (let index = 0; index < this.products.length; index++) {
        let cont = {id: this.products[index].id, quantity: 1, item_price: this.products[index].amount}

        conts.push(cont)
      
      }
      
      window.fbq('track', 'Purchase', {
          value: this.subtotal,
          currency: 'USD',
          contents: conts,
          content_type: 'product'
        });
      }
    
  
    
           
      if (number==2  ){
          this.$router.push({ name: "pending-confirmation", params: { id: this.event.slug } });
        }else{

          
          this.$router.push({ name: "confirmation", params: { id: this.event.slug}, query: { co: tck } });
        }
    
  },






          


    formatDate(dateString){
        const objectDate  = new Date(dateString);
        
        const year = objectDate.getFullYear();

const month = String(objectDate.getMonth() + 1).padStart(2, '0');

const day = String(objectDate.getDate()).padStart(2, '0');


        let format = day + "." + month + "." + year
              // Then specify how you want your dates to be formatted
         return format;
    },




    validateProc(){
      let format = false

      if(this.loadingEvent && !this.wanted){  

        format = false

      }else if (this.loadingEvent && this.wanted){
        format = true
      }else if (!this.loadingEvent && !this.wanted){
 
        format = true

      }else if(!this.loadingEvent && this.wanted){

        format = true
      }



         return format;
    },







  },
 

};
</script>

<style>


.vanilla-zoom {
  width: 100%;
}

.vanilla-zoom .sidebar {
  width: 100%;
  display: flex;
}

.vanilla-zoom .sidebar img.small-preview {
  width: 60px;
  margin-right: 5px;
  cursor: pointer;
  opacity: .5;
}

.vanilla-zoom .sidebar img.small-preview.active, .vanilla-zoom .sidebar img.small-preview:hover {
  opacity: 1;
}

.vanilla-zoom .sidebar img.small-preview:last-child {
  margin-right: 0;
}

.vanilla-zoom .zoomed-images {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}


.vanilla-zoom .container-phpto {
  width: 100%;
  height: 300px;
  flex: 1;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  margin-bottom: 5px;
}




*, *::before, *::after {
  box-sizing: border-box;
}

.clean-block.clean-product .product-info .info .summary {
  border-top: 1px solid white;
  padding-top: 30px;
}

.clean-block.clean-product .gallery {
  padding: 40px;
  background-color: rgb(165, 157, 157);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}


 .searchcode{
       position: relative;
       box-shadow: 0 0 40px rgba(51, 51, 51, .1);
         
       }
   
       .searchcode .fa-search{

        position: absolute;
        top: 15px;
        left: 16px;

       }

       .searchcode button{
        position: absolute;
        top: 5px;
        right: 5px;
        height: 30px;
        width: 110px;
        text-align: center;
        vertical-align: middle;
       }

       
.searchcode .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}


    
</style>